import { Fragment } from 'react';
import Shaxmatka2Row from './Shaxmatka2Row';
import HOME_TYPE from 'shared/homeTypeList';
import { useTranslation } from 'react-i18next';
import Shaxmatka2PadezdItem from './Shaxmatka2PodezItem';

const Shaxmatka2BlockItem = ({
  blockIndex,
  blockItem,
  toggleSelectionItem = () => {},
  settingsMenuValue = '',
}) => {
  const block = {
    id: blockItem?.id,
    name: blockItem?.name,
    objects_id: blockItem?.objects_id,
    room_number: blockItem?.room_number,
  };
  return (
    <div>
      <div className='my-2 ml-8 leading-4'>
        <span className='font-medium text-base text-line-1'>
          {blockItem?.name}
        </span>
      </div>
      <div className='sheet-column flex gap-0'>
        {blockItem &&
          blockItem?.padezd?.length > 0 &&
          blockItem?.padezd.map((padezd, index) => (
            <div
              className='sheet-column'
              key={`block-${padezd?.id}-columns`}
              id={`block-${padezd?.id}-columns`}
            >
              <Shaxmatka2PadezdItem
                block={block}
                padezdItem={padezd}
                padezdIndex={index}
                blockIndex={blockIndex}
                toggleSelectionItem={toggleSelectionItem}
                settingsMenuValue={settingsMenuValue}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Shaxmatka2BlockItem;
