import {
  Button,
  ButtonBase,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
} from '@mui/material';
import BackButton from 'components/ui/BackButton';
import FormActionButtons from 'components/ui/form/FormActionButtons';
import FormCurrencyField from 'components/ui/form/FormCurrencyField';
import FormPhoneField2 from 'components/ui/form/FormPhoneField2';
import FormSelectField from 'components/ui/form/FormSelectField';
import FormSelectWithPlaceholderLabelField from 'components/ui/form/FormSelectWithPlaceholderLabelField';
import FormTextField from 'components/ui/form/FormTextField';
import { useFormik } from 'formik';
import useAxiosPrivate from 'hooks/useAxiosPrivate';
import useFormSubmit from 'hooks/useFormSubmit';
import useTopPanel from 'hooks/useTopPanel';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import * as yup from 'yup';
import CRMCardLeadHistory from './CRMCardLeadHistory';
import moment from 'moment';
import FormTextField2 from 'components/ui/form/FormTextField2';

const validationSchema = yup.object({
  name: yup.string().min(3).required(),
  crm_block_id: yup.string().nullable(),
  crm_block_item_id: yup.string(),
  user_id: yup.string().nullable(),
  budget: yup.number().nullable(),
  operator_id: yup.string().nullable(),
  object_id: yup.string().nullable(),
  source_id: yup.string().nullable(),
  reject_type_id: yup.string().nullable(),
  contacts: yup
    .array()
    .of(
      yup.object({
        id: yup.string().optional(),
        name: yup.string().min(1).nullable(),
        region_id: yup.number().nullable(),
        phones: yup
          .array()
          .of(
            yup.object({
              id: yup.string().optional(),
              phone: yup.string().length(13).nullable(),
            })
          )
          .min(1),
      })
    )
    .min(1),
});

const CRMCardAddEdit = () => {
  const { setComponent } = useTopPanel();
  const { id } = useParams();
  const { t } = useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const { submit, isSubmitting } = useFormSubmit();
  const [hasError, setHasError] = useState(false);
  const [leadHistory, setLeadHistory] = useState({});
  const [leadTasks, setLeadTasks] = useState([]);

  const formik = useFormik({
    initialValues: {
      name: '',
      crm_block_id: '',
      crm_block_item_id: '',
      user_id: '',
      budget: '',
      operator_id: '',
      object_id: '',
      source_id: '',
      reject_type_id: '',
      contacts: [
        {
          name: '',
          region_id: '',
          phones: [
            {
              phone: '',
            },
          ],
        },
      ],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (id) {
        submit(
          { type: 'put', contentType: 'simple' },
          values,
          `/crm/crm-lead/${id}`,
          values.name,
          null,
          true,
          refetch
        );
      } else
        submit(
          { type: 'post', contentType: 'simple' },
          values,
          '/crm/crm-lead',
          values.name,
          null,
          true
        );
    },
  });

  const { isLoading, isFetching, refetch } = useQuery({
    queryKey: 'crmLeadSingle',
    queryFn: async function () {
      const response = await axiosPrivate.get(`/crm/crm-lead/edit/${id}`);
      return response.data.data;
    },
    onSuccess: (data) => {
      if (data && data?.lead) {
        formik.setValues({
          name: data?.lead?.name || '',
          crm_block_id: data?.lead?.crm_block_item?.crm_block_id || '',
          crm_block_item_id: data?.lead?.crm_block_item_id || '',
          user_id: data?.lead?.user_id || '',
          budget: data?.lead?.budget || '',
          operator_id: data?.lead?.operator_id || '',
          object_id: data?.lead?.object_id || '',
          source_id: data?.lead?.source_id || '',
          reject_type_id: data?.lead?.reject_type_id || '',
          contacts:
            data?.lead?.contacts && data?.lead?.contacts.length > 0
              ? data?.lead?.contacts
              : [
                  {
                    name: '',
                    region_id: '',
                    phones: [
                      {
                        phone: '',
                      },
                    ],
                  },
                ],
        });
      }
      if (data && data?.history) {
        setLeadHistory(sortHistoryData(data.history));
        if (data && data?.lead && data?.lead?.tasks) {
          setLeadTasks(sortHistoryData({ ...data?.lead?.tasks }));
        }
      }
    },
    enabled: !hasError && !!id,
    onError: (error) => {
      setHasError(true);
    },
    retry: false,
  });

  const handleUpdateLeadHistory = async () => {
    const response = await axiosPrivate.get(`/crm/crm-lead/edit/${id}`);
    if (response?.data?.data && response?.data?.data?.history) {
      setLeadHistory(sortHistoryData({ ...response?.data?.data?.history }));
      if (
        response?.data?.data &&
        response?.data?.data?.lead &&
        response?.data?.data?.lead?.tasks
      ) {
        setLeadTasks(sortHistoryData({ ...response?.data?.data?.lead?.tasks }));
      }
    }
  };

  const sortHistoryData = (data) => {
    let uniqDay = Array.from(
      new Set(
        Object.keys(data).map((item) =>
          moment(data[item]?.created_at).format('DD_MM_YYYY')
        )
      )
    );
    uniqDay = uniqDay.sort((a, b) =>
      moment(b, 'DD_MM_YYYY').diff(moment(a, 'DD_MM_YYYY'))
    );
    let sortedData = Object.keys(data)
      .map((item) => data[item])
      .sort((a, b) => moment(a?.created_at).diff(b?.created_at));
    let formedData = {};
    for (let i = 0; i < uniqDay.length; i++) {
      formedData[uniqDay[i]] = sortedData.filter(
        (sD) => moment(sD?.created_at).format('DD_MM_YYYY') === uniqDay[i]
      );
    }
    return formedData;
  };

  const handleAddPhone = (contactInd) => {
    formik.setFieldValue(
      `contacts.${contactInd}.phones`,
      [
        ...JSON.parse(
          JSON.stringify(formik.values.contacts[contactInd].phones)
        ),
        {
          phone: '',
        },
      ],
      true
    );
  };

  const handleAddContact = () => {
    formik.setFieldValue(
      'contacts',
      [
        ...JSON.parse(JSON.stringify(formik.values.contacts)),
        {
          name: '',
          region_id: '',
          phones: [
            {
              phone: '',
            },
          ],
        },
      ],
      true
    );
  };

  const handleRemoveContact = (contactInd) => {
    let newContacts = JSON.parse(JSON.stringify(formik.values.contacts)).filter(
      (item, index) => index !== contactInd
    );
    formik.setFieldValue('contacts', [...newContacts], true);
  };

  useEffect(() => {
    setComponent(
      <div className='crm-card-add-edit-top-wrapper'>
        <BackButton defaultPath='/admin/crm' />
        <div className='ml-4 mb-6'>
          <FormTextField
            delay={0}
            duration={0}
            label='Nomi'
            fieldName='name'
            formik={formik}
            variant='standard'
          />
        </div>

        {formik.values.contacts?.[0]?.phones?.[0]?.phone ? (
          <a
            href={`tel:${formik.values.contacts?.[0]?.phones?.[0]?.phone}`}
            className='no-underline'
          >
            <Button variant='action' color='info' className='!ml-2'>
              <i className='bi bi-telephone-outbound-fill text-lg' />
            </Button>
          </a>
        ) : (
          ''
        )}
      </div>
    );
  }, [
    formik.values.name,
    formik.errors.name,
    formik.touched.name,
    formik.values.contacts?.[0]?.phones?.[0]?.phone,
  ]);

  return (
    <div className='component-add-edit-wrapper crm-card-add-edit-wrapper'>
      <div className='component-add-edit-body crm-card-add-edit-body'>
        {isLoading || isFetching ? (
          <div className='circular-progress-box py-5'>
            <CircularProgress size={35} />
          </div>
        ) : (
          <div className='crm-card-add-edit-body-wrapper'>
            <div className='add-edit-body-wrapper'>
              <form onSubmit={formik.handleSubmit}>
                <Grid
                  container
                  spacing={{ xs: 2, sm: 3, lg: 3 }}
                  rowSpacing={1}
                  columns={{ xs: 12, sm: 12, lg: 12 }}
                >
                  <Grid item={true} sm={12} xs={12}>
                    <div className='item-wrapper'>
                      <div className='item-label'>
                        {t('common.filter.block')}
                      </div>
                      <div className='item-value'>
                        <FormSelectField
                          delay={0}
                          duration={0}
                          fieldName='crm_block_id'
                          label=''
                          formik={formik}
                          path={'/crm/block'}
                          variant='standard'
                          isItemSizeSmall={true}
                        />
                      </div>
                    </div>
                  </Grid>

                  {formik.values.crm_block_id && (
                    <Grid item={true} sm={12} xs={12}>
                      <div className='item-wrapper'>
                        <div className='item-label'>
                          {t('common.filter.crm_block_id')}
                        </div>
                        <div className='item-value'>
                          <FormSelectField
                            delay={0}
                            duration={0}
                            fieldName='crm_block_item_id'
                            label=''
                            formik={formik}
                            path={`/crm/block-item/${formik.values.crm_block_id}`}
                            variant='standard'
                            isItemSizeSmall={true}
                            pathChangeable={true}
                          />
                        </div>
                      </div>
                    </Grid>
                  )}

                  <Grid item={true} sm={12} xs={12}>
                    <div className='item-wrapper'>
                      <div className='item-label'>
                        {' '}
                        {t('common.fields.staff')}
                      </div>
                      <div className='item-value'>
                        <FormSelectField
                          delay={0}
                          duration={0}
                          fieldName='user_id'
                          label=''
                          formik={formik}
                          path={'/dictionary/staffes'}
                          variant='standard'
                          isItemSizeSmall={true}
                        />
                      </div>
                    </div>
                  </Grid>

                  <Grid item={true} sm={12} xs={12}>
                    <div className='item-wrapper'>
                      <div className='item-label'>
                        {t('crm.block.addModal.budget')}
                      </div>
                      <div className='item-value'>
                        <FormCurrencyField
                          delay={0}
                          duration={0}
                          label=''
                          fieldName='budget'
                          formik={formik}
                          variant='standard'
                        />
                      </div>
                    </div>
                  </Grid>

                  <Grid item={true} sm={12} xs={12}>
                    <div className='item-wrapper'>
                      <div className='item-label'>
                        {t('common.typeList.role.operator')}
                      </div>
                      <div className='item-value'>
                        <FormSelectWithPlaceholderLabelField
                          delay={0}
                          duration={0}
                          fieldName='operator_id'
                          label=''
                          formik={formik}
                          path={'/crm/operator'}
                          itemPlaceholderLabel='number'
                          variant='standard'
                          isItemSizeSmall={true}
                        />
                      </div>
                    </div>
                  </Grid>

                  <Grid item={true} sm={12} xs={12}>
                    <div className='item-wrapper'>
                      <div className='item-label'>
                        {t('common.fields.objectName')}
                      </div>
                      <div className='item-value'>
                        <FormSelectField
                          delay={0}
                          duration={0}
                          fieldName='object_id'
                          label=''
                          formik={formik}
                          path={'/admin/object/index'}
                          variant='standard'
                          isItemSizeSmall={true}
                        />
                      </div>
                    </div>
                  </Grid>

                  <Grid item={true} sm={12} xs={12}>
                    <div className='item-wrapper'>
                      <div className='item-label'>
                        {t('common.fields.source')}
                      </div>
                      <div className='item-value'>
                        <FormSelectField
                          delay={0}
                          duration={0}
                          fieldName='source_id'
                          label=''
                          formik={formik}
                          path={'/crm/source'}
                          variant='standard'
                          isItemSizeSmall={true}
                        />
                      </div>
                    </div>
                  </Grid>

                  <Grid item={true} sm={12} xs={12}>
                    <div className='item-wrapper'>
                      <div className='item-label'>
                        {t('crm.block.addModal.reason')}
                      </div>
                      <div className='item-value'>
                        <FormSelectField
                          delay={0}
                          duration={0}
                          fieldName='reject_type_id'
                          label=''
                          formik={formik}
                          path={'/crm/reject-type'}
                          variant='standard'
                          isItemSizeSmall={true}
                        />
                      </div>
                    </div>
                  </Grid>

                  <Grid item={true} sm={12} xs={12}>
                    <div className='contact-divider'></div>
                  </Grid>

                  {formik.values.contacts &&
                    formik.values.contacts.length > 0 &&
                    formik.values.contacts.map((contact, contactInd) => (
                      <Grid
                        item={true}
                        sm={12}
                        xs={12}
                        key={`contact-${contactInd}`}
                      >
                        <div className='mt-2 my-shadow-1 -mx-1 p-2 rounded-lg leading-5 relative'>
                          <div className='item-wrapper contact-details'>
                            <i className='bi bi-person-circle contact-person-icon' />
                            <FormTextField2
                              delay={0}
                              duration={0}
                              label=''
                              fieldName={`contacts.${contactInd}.name`}
                              formik={formik}
                              variant='standard'
                            />
                          </div>
                          <div>
                            {contact.phones.map((item, index) => (
                              <div
                                className='item-wrapper contact-phones'
                                key={`contact-${contactInd}-phone-${index}`}
                              >
                                <div className='item-label contact-label'>
                                  {t('common.fields.phone')}
                                </div>
                                <div className='item-value contact-value phone-value'>
                                  {index === contact.phones.length - 1 ? (
                                    <ButtonBase
                                      className='add-phone-btn'
                                      onClick={() => handleAddPhone(contactInd)}
                                    >
                                      <i className='bi bi-plus-circle' />
                                    </ButtonBase>
                                  ) : (
                                    <div className='mr-6'></div>
                                  )}

                                  <FormPhoneField2
                                    delay={0}
                                    duration={0}
                                    label=''
                                    fieldName={`contacts.${contactInd}.phones.${index}.phone`}
                                    formik={formik}
                                    variant='standard'
                                    withNestedField={true}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                          <div>
                            <div className='item-wrapper'>
                              <div className='item-label contact-label'>
                                {t('common.fields.region')}
                              </div>
                              <div className='item-value contact-value'>
                                <FormSelectField
                                  delay={0}
                                  duration={0}
                                  fieldName={`contacts.${contactInd}.region_id`}
                                  label=''
                                  formik={formik}
                                  path={'/dictionary/regions'}
                                  withNestedField={true}
                                  variant='standard'
                                  isItemSizeSmall={true}
                                />
                              </div>
                            </div>
                          </div>
                          {formik.values.contacts.length > 1 && (
                            <div
                              className='close-btn-wrapper'
                              onClick={() => handleRemoveContact(contactInd)}
                            >
                              <IconButton
                                variant='onlyIcon'
                                color='error'
                                size='small'
                              >
                                <i className='bi bi-x' />
                                {t('crm.block.addModal.addContact')}
                              </IconButton>
                            </div>
                          )}
                        </div>
                      </Grid>
                    ))}

                  <Grid item={true} sm={12} xs={12}>
                    <div className='-mx-1 mt-1.5'>
                      <Button
                        color='secondary'
                        variant='outlined'
                        fullWidth
                        onClick={() => handleAddContact()}
                      >
                        <i className='bi bi-plus-circle mr-1' />
                      </Button>
                    </div>
                  </Grid>

                  <Grid item={true} sm={12} xs={12}>
                    <FormActionButtons delay={0} isSubmitting={isSubmitting} />
                  </Grid>
                </Grid>
              </form>
            </div>
            <div className='lead-history-body-wrapper'>
              <CRMCardLeadHistory
                history={leadHistory}
                tasks={leadTasks}
                refetchFn={handleUpdateLeadHistory}
                isUserNew={!id}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default memo(CRMCardAddEdit);
