import CURRENCY_TYPE from 'shared/currencyTypeList';

function getShaxmatkaFilterData(blocks = [], currency) {
  let result = {
    rooms: { marks: [] },
    repairedPrice: { marks: [] },
    square: { marks: [] },
    stage: { marks: [] },
  };
  blocks.forEach((block) => {
    result.rooms.marks = [...result.rooms.marks, getRoomsMarks(block)];
    result.repairedPrice.marks = [
      ...result.repairedPrice.marks,
      getPriceMarks(block, 'repaired', currency),
    ];
    result.square.marks = [...result.square.marks, getMarks(block, 'square')];
    result.stage.marks = [...result.stage.marks, getMarks(block, 'stage')];
  });
  return {
    rooms: getSortedMarks(result.rooms.marks),
    repairedPrice: getSortedMarks(result.repairedPrice.marks),
    square: getSortedSquareMarks(result.square.marks),
    stage: getSortedMarks(result.stage.marks),
  };
}

const getMarks = (block, code) => {
  const fieldData = [];
  if (block?.padezd?.length > 0) {
    block?.padezd?.forEach(({ homes }) => {
      homes?.forEach((home) => {
        if (home[code] && home[code] !== 0) {
          fieldData.push(parseFloat(home[code]));
        }
      });
    });
  }
  return Array.from(new Set(fieldData));
};

const getPriceMarks = (block, code, currency) => {
  return Array.from(
    new Set(
      block?.homes
        .map((item) =>
          item[code]
            ? item?.isvalute === CURRENCY_TYPE.VALUTE.code
              ? parseFloat(item[code]) * parseFloat(currency)
              : parseFloat(item[code])
            : null
        )
        .filter((item) => item !== 0 && item !== null && item !== undefined)
    )
  );
};

const getRoomsMarks = (block) => {
  return Array.from(
    new Set(
      block?.homes
        .map((item) => (item.rooms ? item.rooms : null))
        .filter((item) => item !== 0 && item !== null && item !== undefined)
    )
  );
};

const getSortedSquareMarks = (marks) => {
  let newMarks = Array.from(new Set(marks.flat()));
  let minValOfArr = Math.min.apply(null, newMarks);
  let maxValOfArr = Math.max.apply(null, newMarks);
  let min = minValOfArr - (minValOfArr % 10);
  let max = maxValOfArr + (10 - (maxValOfArr % 10));
  let newArr = Array.from({ length: 11 }, (item, index) => ({
    value: index + 1,
    label: min + ((max - min) / 10) * index,
  }));

  return {
    marks: newArr,
    min: 1,
    max: newArr.length,
  };
};

const getSortedMarks = (marks) => {
  let newMarks = Array.from(new Set(marks.flat()))
    .sort((a, b) => a - b)
    .map((item, index) => ({
      value: index + 1,
      label: item,
    }));
  return {
    marks: newMarks,
    min: 1,
    max: newMarks.length,
  };
};

export default getShaxmatkaFilterData;
