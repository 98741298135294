import { ButtonBase, Grid } from '@mui/material';
import React, { Fragment } from 'react';

const Shaxmatka2Row = ({
  homesData,
  blockIndex,
  padezdIndex,
  floorNumber,
  size,
  toggleSelectionItem = () => {},
  settingsMenuValue = '',
}) => {
  return (
    <Fragment>
      <Grid
        container
        rowSpacing={0}
        columns={{
          xs: parseInt(size),
          sm: parseInt(size),
        }}
        className={'sheet-home-row'}
      >
        {padezdIndex == 0 && (
          <Grid
            item={true}
            sm={1}
            xs={1}
            className='sheet-home-cell !-ml-2.5 !mr-0'
          >
            <ButtonBase
              className='sheet-home-row-floor h-full w-8 text-sm font-medium !cursor-default'
              disableRipple
            >
              {floorNumber}
            </ButtonBase>
          </Grid>
        )}
        {homesData.filter((home) => home.stage === floorNumber).length > 0
          ? homesData
              .filter((home) => home.stage === floorNumber)
              .map((item) => (
                <Grid
                  item={true}
                  sm={1}
                  xs={1}
                  key={`block-${blockIndex}-${padezdIndex}-home-${item.id}`}
                  id={`block-${blockIndex}-${padezdIndex}-home-${item.id}`}
                  className={`sheet-home-cell floor-${floorNumber} `}
                >
                  <ButtonBase
                    className={`block-${blockIndex}-${padezdIndex}-home home-item status-${
                      item.status
                    }${item.disabled ? ' is-disabled' : ''}`}
                    id={`home-${item.id}`}
                    onClick={() =>
                      toggleSelectionItem(item.id, padezdIndex, blockIndex)
                    }
                  >
                    {settingsMenuValue === 'rooms' ? (
                      !(item.stage && parseInt(item.stage) < 0) ? (
                        item.rooms ? (
                          <div>{item.rooms}x</div>
                        ) : (
                          ''
                        )
                      ) : (
                        <div>P</div>
                      )
                    ) : (
                      <div>{item.number}</div>
                    )}
                  </ButtonBase>
                </Grid>
              ))
          : [1].map((item) => (
              <Grid
                item={true}
                sm={1}
                xs={1}
                key={`block-${blockIndex}-home-empty-${item}`}
                id={`block-${blockIndex}-home-empty-${item}`}
                className={`sheet-home-cell sheet-home-empty-cell floor-${floorNumber}`}
              >
                <ButtonBase className='home-item is-empty'>
                  <div className='home-item-data'></div>
                </ButtonBase>
              </Grid>
            ))}
      </Grid>
    </Fragment>
  );
};

export default Shaxmatka2Row;
