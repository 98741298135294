import {
  Button,
  ButtonBase,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { Trans, useTranslation } from 'react-i18next';
import { Fragment, memo, useEffect, useState } from 'react';

import useCurrency from 'hooks/useCurrency';
import REPAIR_TYPE from 'shared/repairTypeList';
import ImagesSwiper from 'components/ImagesSwiper';
import CURRENCY_TYPE from 'shared/currencyTypeList';
import { currencyTypeSelectOptions } from 'shared/selectOptionsList';
import { formatMultiplies, formatSingle } from 'utils/formatOddDecimal';
import EditableNumberField from 'components/ui/editable-fields/EditableNumberField';
import SimpleNumberField from 'components/ui/simple-fields/numeric-format/SimpleNumberField';
import SimpleRadioGroupField from 'components/ui/simple-fields/radio-group/SimpleRadioGroupField';
import SimpleToggleButtonGroup from 'components/ui/simple-fields/toggle-button-group/SimpleToggleButtonGroup';

const CalculationByHomeData = ({
  homeData = {},
  object = {},
  order = 0,
  enableRemove = true,
  setData = () => {},
  removeFn = () => {},
}) => {
  const { t } = useTranslation();
  const [fullPrice, setFullPrice] = useState(0);
  const [sellPrice, setSellPrice] = useState(0);
  const [startPrice, setStartPrice] = useState(0);
  const [startPricePercentage, setStartPricePercentage] = useState(
    homeData?.start
  );
  const [priceForSquare, setPriceForSquare] = useState(
    homeData?.isrepaired === REPAIR_TYPE.REPAIRED.code
      ? homeData?.repaired
      : homeData?.norepaired
  );
  const [
    square,
    // setSquare
  ] = useState(homeData.square);
  const [discount, setDiscount] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [month, setMonth] = useState(48);
  const [priceForMonth, setPriceForMonth] = useState(0);
  const [currency, setCurrency] = useState(
    homeData?.isvalute || CURRENCY_TYPE.SUM.code
  );
  const [
    monthSelectOptions,
    // setMonthSelectOptions
  ] = useState([
    {
      code: 24,
      label: ['shaxmatka.moreHomeDetails.monthValue', { value: 24 }],
    },
    {
      code: 36,
      label: ['shaxmatka.moreHomeDetails.monthValue', { value: 36 }],
    },
    {
      code: 48,
      label: ['shaxmatka.moreHomeDetails.monthValue', { value: 48 }],
    },
  ]);
  const { currencyData } = useCurrency();

  const handleChangeMonth = (value) => {
    setMonth(parseInt(value));
    setPriceForMonth(formatSingle((sellPrice - startPrice) / parseInt(value)));
  };

  const handleChangeStartPrice = (value) => {
    setStartPrice(value);
    setStartPricePercentage(
      sellPrice ? formatSingle((value * 100) / sellPrice, 3) : 0
    );
    setPriceForMonth(formatSingle((sellPrice - value) / month));
  };

  const handleChangeStartPricePercent = (value) => {
    setStartPrice((sellPrice * value) / 100);
    setStartPricePercentage(value);
    // setStartPricePercentage(
    //   sellPrice ? formatSingle((value * 100) / sellPrice, 3) : 0
    // );
    setPriceForMonth(
      formatSingle((sellPrice - (sellPrice * value) / 100) / month)
    );
  };

  const handleCurrencyTypeChange = (value) => {
    setCurrency(value);
    if (currencyData && currencyData.sum) {
      if (value === CURRENCY_TYPE.VALUTE.code) {
        setFullPrice(fullPrice / currencyData.sum);
        setSellPrice(sellPrice / currencyData.sum);
        setDiscount(discount / currencyData.sum);
        setStartPrice(startPrice / currencyData.sum);
        setPriceForMonth(priceForMonth / currencyData.sum);
        setPriceForSquare(priceForSquare / currencyData.sum);
      } else {
        setFullPrice(fullPrice * currencyData.sum);
        setSellPrice(sellPrice * currencyData.sum);
        setDiscount(discount * currencyData.sum);
        setStartPrice(startPrice * currencyData.sum);
        setPriceForMonth(priceForMonth * currencyData.sum);
        setPriceForSquare(priceForSquare * currencyData.sum);
      }
    }
  };

  const handleChangeSellPrice = (newSellPrice) => {
    if (newSellPrice <= fullPrice) {
      setDiscount(fullPrice - newSellPrice);
      setDiscountPercentage(
        fullPrice
          ? formatSingle(((fullPrice - newSellPrice) * 100) / fullPrice, 2)
          : 0
      );
      setSellPrice(newSellPrice);
      setPriceForMonth(formatSingle((newSellPrice - startPrice) / month));
      setPriceForSquare(formatSingle(newSellPrice / square));
    }
  };

  const handleChangeDiscount = (disc) => {
    const newDisc = disc || 0;
    if (newDisc <= fullPrice) {
      setDiscount(newDisc);
      setDiscountPercentage(
        fullPrice ? formatSingle((newDisc * 100) / fullPrice, 2) : 0
      );
      setSellPrice(() => fullPrice - newDisc);
      setPriceForMonth(
        formatSingle((sellPrice - newDisc - startPrice) / month)
      );
      setPriceForSquare(formatSingle((fullPrice - newDisc) / square));
    }
  };
  const handleChangeDiscountPercentage = (per) => {
    const newPer = per * 0.01 || 0;
    if (newPer * fullPrice <= fullPrice) {
      setDiscount(newPer * fullPrice);
      setDiscountPercentage(newPer * 100);
      setSellPrice(() => fullPrice * (1 - newPer));
      setPriceForMonth(
        formatSingle((sellPrice - newPer * fullPrice - startPrice) / month)
      );
      setPriceForSquare(
        formatSingle((fullPrice - newPer * fullPrice) / square)
      );
    }
  };

  const handleChangePriceSquare = (newPriceForSquare) => {
    let currPriceByRepaired =
      homeData?.isrepaired === REPAIR_TYPE.REPAIRED.code
        ? parseFloat(homeData?.repaired)
        : parseFloat(homeData?.norepaired);

    let maxPrice =
      homeData?.isvalute === CURRENCY_TYPE.SUM.code &&
      currency === CURRENCY_TYPE.VALUTE.code
        ? currPriceByRepaired / currencyData.sum
        : homeData?.isvalute === CURRENCY_TYPE.VALUTE.code &&
          currency === CURRENCY_TYPE.SUM.code
        ? currPriceByRepaired * currencyData.sum
        : currPriceByRepaired;

    if (newPriceForSquare <= maxPrice) {
      let newDiscount = formatMultiplies(
        [maxPrice - newPriceForSquare, homeData.square],
        2
      );
      setDiscount(newDiscount);
      setDiscountPercentage(formatSingle((newDiscount * 100) / fullPrice, 2));
      let newSellP = formatMultiplies([newPriceForSquare, homeData.square], 2);
      setSellPrice(newSellP);
      setPriceForMonth(formatSingle(newSellP - startPrice) / month, 2);
      setPriceForSquare(newPriceForSquare);
    }
  };

  useEffect(() => {
    const sellP =
      homeData?.isrepaired === REPAIR_TYPE.REPAIRED.code
        ? homeData?.repaired && homeData?.square
          ? formatMultiplies([homeData.repaired, homeData.square], 2)
          : 0
        : homeData?.norepaired && homeData?.square
        ? formatMultiplies([homeData.norepaired, homeData.square], 2)
        : 0;
    setFullPrice(sellP);
    setSellPrice(sellP);
    setPriceForMonth(
      formatSingle(
        (sellP - formatSingle((sellP * +homeData?.start) / 100, 2)) / month
      )
    );
    setStartPrice(formatSingle((sellP * +homeData?.start) / 100, 2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setData(order, {
      isvalute: currency,
      fullPrice,
      sellPrice,
      startPrice,
      startPricePercentage,
      month,
      priceForMonth,
      priceForSquare,
      discount,
      discountPercentage,
    });
  }, [
    order,
    currency,
    fullPrice,
    sellPrice,
    startPrice,
    startPricePercentage,
    month,
    priceForMonth,
    priceForSquare,
    discount,
    discountPercentage,
  ]);

  useEffect(() => {
    if (startPricePercentage) {
      setStartPrice(formatSingle((sellPrice * startPricePercentage) / 100, 2));
    }
  }, [sellPrice, discount, discountPercentage, startPricePercentage]);

  return (
    <div className='calculation-wrapper'>
      <div className='remove-btn-wrapper'>
        {enableRemove && (
          <IconButton
            variant='onlyIcon'
            color='error'
            onClick={() => removeFn(order)}
          >
            <i className='bi bi-x' />
          </IconButton>
        )}
      </div>
      <div>
        <div className='home-details-header'>
          {/* Xonadon ma'lumotlari */}
          {t('order.modal.add.title')}
          <div className='currency-data'>
            {currency === CURRENCY_TYPE.VALUTE.code && (
              <div className='mr-2 whitespace-nowrap'>
                {t('common.fields.currency')}:{' '}
                <NumericFormat
                  value={currencyData?.sum}
                  allowNegative={false}
                  displayType={'text'}
                  thousandSeparator={' '}
                  decimalScale={1}
                  className='w-full text-right'
                  suffix=' UZS'
                />
              </div>
            )}
            <SimpleToggleButtonGroup
              delay={0}
              duration={0}
              value={currency}
              changeFn={handleCurrencyTypeChange}
              options={currencyTypeSelectOptions}
              size='small'
              fullWidth={true}
              buttonClassName='whitespace-nowrap'
            />
          </div>
        </div>
        <div className='images-wrapper'>
          <div className='w-[300px] h-[200px]'>
            {homeData?.plan &&
              homeData?.plan?.link &&
              homeData?.plan?.link2d && (
                <ImagesSwiper
                  imageURLs={[homeData?.plan?.link, homeData?.plan?.link2d]}
                />
              )}
          </div>
          <div className='home-rooms-and-discount'>
            <span className='home-data-divider'></span>
            <div className='home-rooms-data'>
              <ButtonBase className='home-rooms-btn'>
                {homeData?.rooms}x
              </ButtonBase>
            </div>
            {discountPercentage ? (
              <div className='home-discount-data'>
                <Button color='warning' variant='outlined' size='small'>
                  <i className='bi bi-tag text-md mr-0.5' />{' '}
                  {discountPercentage}%
                </Button>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className='text-sm mt-1 p-2'>
            <div>
              ={object?.name}, {homeData?.blocks?.name}, №{homeData?.number}
            </div>
            <div>
              <EditableNumberField
                initialValue={sellPrice}
                changeFn={handleChangeSellPrice}
                decimalScale={1}
              >
                <NumericFormat
                  value={sellPrice}
                  allowNegative={false}
                  displayType={'text'}
                  thousandSeparator={' '}
                  decimalScale={1}
                  className='price-text'
                  suffix={currency === '1' ? ' $' : ' UZS'}
                />
              </EditableNumberField>
            </div>
            <div>
              {homeData?.square}{' '}
              <Trans i18nKey='common.global.meter'>
                m<sup>2</sup>
              </Trans>{' '}
              •{' '}
              <EditableNumberField
                initialValue={priceForSquare}
                changeFn={handleChangePriceSquare}
                decimalScale={1}
              >
                <NumericFormat
                  value={priceForSquare}
                  allowNegative={false}
                  displayType={'text'}
                  thousandSeparator={' '}
                  decimalScale={1}
                  className='price-text'
                  suffix={currency === '1' ? ' $' : ' UZS'}
                />
              </EditableNumberField>
            </div>
          </div>

          {discount > 0 && (
            <Fragment>
              <div className='w-full relative'>
                <span className='home-data-divider'></span>
              </div>
              <div className='discount-details-wrapper'>
                <div>
                  <i className='bi bi-tag discount-icon' /> {discountPercentage}
                  {t('shaxmatka.moreHomeDetails.appliedDiscount')}
                </div>
                <div>
                  <i className='bi bi-patch-check discount-icon' />{' '}
                  <NumericFormat
                    value={discount}
                    allowNegative={false}
                    displayType={'text'}
                    thousandSeparator={' '}
                    decimalScale={1}
                    className='w-full text-right'
                    suffix={currency === '1' ? ' $' : ' UZS'}
                  />
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>

      <div className='payment-details-wrapper'>
        <div className='mb-2'>{t('order.view.paymentContracts')}</div>
        <Grid
          container
          spacing={2}
          rowSpacing={2}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <Grid item={true} md={3} sm={4} xs={12}>
            <div className='payment-item-wrapper'>
              <div className='payment-item-icon-wrapper'>
                <i className='bi bi-currency-dollar payment-item-icon' />
              </div>
              <div>
                <div className='payment-item-label'>
                  {t('payment.add.contract.totalSum')}
                </div>
                <NumericFormat
                  value={fullPrice}
                  allowNegative={false}
                  displayType={'text'}
                  thousandSeparator={' '}
                  decimalScale={1}
                  className='w-full text-right'
                  suffix={currency === '1' ? ' $' : ' UZS'}
                />
              </div>
            </div>
          </Grid>
          <Grid item={true} md={3} sm={4} xs={12}>
            <div className='payment-item-wrapper'>
              <div className='payment-item-icon-wrapper'>
                <i className='bi bi-currency-dollar payment-item-icon' />
              </div>
              <div>
                <div className='payment-item-label'>
                  {t('shaxmatka.moreHomeDetails.discount')}
                </div>
                <EditableNumberField
                  initialValue={discount}
                  changeFn={handleChangeDiscount}
                  decimalScale={1}
                >
                  <NumericFormat
                    value={discount}
                    allowNegative={false}
                    displayType={'text'}
                    thousandSeparator={' '}
                    decimalScale={1}
                    size={'small'}
                    className='w-full text-right'
                    suffix={currency === '1' ? ' $' : ' UZS'}
                  />
                </EditableNumberField>
                (
                <EditableNumberField
                  initialValue={discountPercentage}
                  changeFn={handleChangeDiscountPercentage}
                  decimalScale={1}
                >
                  <NumericFormat
                    value={discountPercentage}
                    allowNegative={false}
                    displayType={'text'}
                    thousandSeparator={' '}
                    decimalScale={1}
                    size={'small'}
                    className='w-full text-right'
                    suffix={' %'}
                  />
                </EditableNumberField>
                )
              </div>
            </div>
          </Grid>
          <Grid item={true} md={3} sm={4} xs={12}>
            <div className='payment-item-wrapper'>
              <div className='payment-item-icon-wrapper'>
                <i className='bi bi-wallet2 payment-item-icon' />
              </div>
              <div>
                <div className='payment-item-label'>
                  {t('shaxmatka.moreHomeDetails.startPrice')}
                </div>
                <NumericFormat
                  value={startPrice}
                  allowNegative={false}
                  displayType={'text'}
                  thousandSeparator={' '}
                  decimalScale={1}
                  className='w-full text-right'
                  suffix={currency === '1' ? ' $' : ' UZS'}
                />
                ({startPricePercentage}%)
              </div>
            </div>
          </Grid>
          <Grid item={true} md={3} sm={4} xs={12}>
            <div className='payment-item-wrapper'>
              <div className='payment-item-icon-wrapper'>
                <i className='bi bi-cash-coin payment-item-icon' />
              </div>
              <div>
                <div className='payment-item-label'>
                  {t('shaxmatka.moreHomeDetails.monthlyPrice')}(
                  {t('shaxmatka.moreHomeDetails.monthValue', {
                    value: month,
                  })}
                  )
                </div>
                <NumericFormat
                  value={priceForMonth}
                  allowNegative={false}
                  displayType={'text'}
                  thousandSeparator={' '}
                  decimalScale={1}
                  className='w-full text-right'
                  suffix={currency === '1' ? ' $' : ' UZS'}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className='mt-2'>
        <div className='mb-2'>{t('order.view.optionalStartPayment')}</div>
        <Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12 }}>
          <Grid item={true} md={4} sm={6} xs={12}>
            <div className='rounded-lg my-shadow-1 p-3'>
              <SimpleNumberField
                delay={0}
                duration={0}
                value={startPricePercentage}
                setValue={handleChangeStartPricePercent}
                label={t('common.fields.startPricePercentage')}
                name='startPrice'
                size='small'
              />
              <SimpleNumberField
                delay={0}
                duration={0}
                value={startPrice}
                setValue={handleChangeStartPrice}
                label={t('common.fields.startPrice')}
                name='startPrice'
                size='small'
              />
              <div className='flex items-center'>
                <SimpleRadioGroupField
                  delay={0}
                  duration={0}
                  name='month'
                  label={t('shaxmatka.moreHomeDetails.month')}
                  value={month}
                  changeFn={handleChangeMonth}
                  options={monthSelectOptions}
                  className='flex'
                  size='small'
                />
                <SimpleNumberField
                  delay={0}
                  duration={0}
                  value={month}
                  setValue={handleChangeMonth}
                  label=''
                  name='monthValue'
                  size='small'
                />
              </div>
            </div>
          </Grid>

          <Grid item={true} md={8} sm={6} xs={12}>
            <TableContainer className='calculation-table'>
              <Table aria-labelledby='tableTitle'>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <span>{t('shaxmatka.moreHomeDetails.startPrice')}</span>
                    </TableCell>
                    <TableCell>
                      <span>{t('shaxmatka.moreHomeDetails.discount')}</span>
                    </TableCell>
                    <TableCell>
                      <span>{t('shaxmatka.moreHomeDetails.sellPrice')}</span>
                    </TableCell>
                    <TableCell>
                      <span>{t('shaxmatka.moreHomeDetails.month')}</span>
                    </TableCell>
                    <TableCell>
                      <span>{t('shaxmatka.moreHomeDetails.monthlyPrice')}</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className='overflow-hidden'>
                  <TableRow hover tabIndex={-1}>
                    <TableCell className='cell-text'>
                      <NumericFormat
                        value={startPrice}
                        allowNegative={false}
                        displayType={'text'}
                        thousandSeparator={' '}
                        decimalScale={1}
                        className='w-full text-right'
                        suffix={currency === '1' ? ' $' : ' UZS'}
                      />
                      ({startPricePercentage}%)
                    </TableCell>
                    <TableCell className='cell-text'>
                      <NumericFormat
                        value={discount}
                        allowNegative={false}
                        displayType={'text'}
                        thousandSeparator={' '}
                        decimalScale={1}
                        className='w-full text-right'
                        suffix={currency === '1' ? ' $' : ' UZS'}
                      />
                      ({discountPercentage}%)
                    </TableCell>
                    <TableCell className='cell-text'>
                      <NumericFormat
                        value={sellPrice}
                        allowNegative={false}
                        displayType={'text'}
                        thousandSeparator={' '}
                        decimalScale={1}
                        className='w-full text-right'
                        suffix={currency === '1' ? ' $' : ' UZS'}
                      />
                    </TableCell>
                    <TableCell className='cell-text'>{month}</TableCell>
                    <TableCell className='cell-text'>
                      <NumericFormat
                        value={priceForMonth}
                        allowNegative={false}
                        displayType={'text'}
                        thousandSeparator={' '}
                        decimalScale={1}
                        className='w-full text-right'
                        suffix={currency === '1' ? ' $' : ' UZS'}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default memo(CalculationByHomeData);
