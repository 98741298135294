import {
	Button,
	ButtonBase,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton
} from "@mui/material"
import FormTextField from "components/ui/form/FormTextField"
import CurrencyFormat from "components/ui/text-formats/CurrencyFormat"
import { useFormik } from "formik"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useNotification from "hooks/useNotification"
import React, { Fragment, useState } from "react"
import getLabelByTypeList from "utils/getLabelByTypeList"
import REPAIR_TYPE from "shared/repairTypeList"
import RESIDENT_TYPE from "shared/residentTypeList"
import * as yup from "yup"
import FormPhoneField from "components/ui/form/FormPhoneField"
import SimpleDateField from "components/ui/simple-fields/date-picker/SimpleDateField"
import useAuth from "hooks/useAuth"
import PhoneFormat from "components/ui/text-formats/PhoneFormat"
import ImagePreviewDialog from "components/ui/dialogs/ImagePreviewDialog"
import { Trans, useTranslation } from "react-i18next"
import { formatMultiplies } from "utils/formatOddDecimal"
import SimpleSwitchField from "components/ui/simple-fields/switch/SimpleSwitchField"
import FormPassportField from "components/ui/form/FormPassportField"
import FormDateField from "components/ui/form/FormDateField"
import FormPatternField from "components/ui/form/FormPatternField"
import FormAutocompleteField from "components/ui/form/FormAutocompleteField"
import axios from "axios"
import CITIZENSHIP_TYPE from "shared/citizenshipTypeList"
import SimpleRadioGroupField from "components/ui/simple-fields/radio-group/SimpleRadioGroupField"
import { citizenshipTypeSelectOptions } from "shared/selectOptionsList"

const validationSchema = yup.object({
	name: yup.string().required("order.modal.add.validation.name"),
	middlename: yup.string().optional(),
	surname: yup.string().required("order.modal.add.validation.surname"),
	phone: yup
		.string()
		.length(17, "order.modal.add.validation.phoneValid")
		.required("order.modal.add.validation.phone")
})

const physicalValidationSchema = yup.object({
	name: yup.string().required("client.addEdit.validation.physical.name"),
	middlename: yup
		.string()
		.required("client.addEdit.validation.physical.middleName"),
	surname: yup.string().required("client.addEdit.validation.physical.surname"),
	phone: yup
		.string()
		.length(17, "client.addEdit.validation.physical.phoneValid")
		.required("client.addEdit.validation.physical.phone"),
	phone2: yup
		.string()
		.length(17, "client.addEdit.validation.physical.phone2Valid")
		.nullable()
		.optional(),
	passport_series: yup
		.string()
		.trim()
		.min(10, "client.addEdit.validation.physical.passportSeriesFormat")
		.max(10, "client.addEdit.validation.physical.passportSeriesFormat")
		.required("client.addEdit.validation.physical.passportSeries"),
	issue: yup
		.date()
		.nullable()
		.typeError("client.addEdit.validation.physical.issueValid")
		.required("client.addEdit.validation.physical.issue"),
	authority: yup
		.string()
		.required("client.addEdit.validation.physical.authority"),
	birthday: yup
		.date()
		.nullable()
		.typeError("client.addEdit.validation.physical.birthdayValid")
		.required("client.addEdit.validation.physical.birthday"),
	region_id: yup.string().required("client.addEdit.validation.physical.region"),
	city: yup.string().required("client.addEdit.validation.physical.city"),
	home: yup.string().required("client.addEdit.validation.physical.address"),
	inn: yup
		.string()
		.trim()
		.min(9, "client.addEdit.validation.physical.tinFormat")
		.max(9, "client.addEdit.validation.physical.tinFormat")
		.required("client.addEdit.validation.physical.tin"),
	pnfl: yup
		.string()
		.trim()
		.min(14, "client.addEdit.validation.physical.pnflFormat")
		.max(14, "client.addEdit.validation.physical.pnflFormat")
		.required("client.addEdit.validation.physical.pnfl"),
	work_place: yup.string().optional()
})

const OrderAddModal = (props) => {
	const { open, setOpen, data: homeData, setRefetch } = props
	const { t } = useTranslation()
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isOrderSubmitting, setIsOrderSubmitting] = useState(false)
	const sendNotification = useNotification()
	const axiosPrivate = useAxiosPrivate()
	const [client, setClient] = useState("")
	const [customerId, setCustomerId] = useState("")
	const [date, setDate] = useState("")
	const [{ user }] = useAuth()
	const [clientsList, setClientsList] = useState([])
	const [clientsLoading, setClientsLoading] = useState(false)
	const [clientsNotFound, setClientsNotFound] = useState(false)
	const [isClientDataMore, setIsClientDataMore] = useState(true)
	const [openHomeLayoutImageDialog, setOpenHomeLayoutImageDialog] =
		useState(false)
	const [isClientSearching, setIsClientSearching] = useState(false)
	const [citizenship, setCitizenship] = useState(
		CITIZENSHIP_TYPE.UZBEKISTAN.code
	)

	const initialValues = {
		name: "",
		middlename: "",
		surname: "",
		phone: ""
	}

	const physicalInitialValues = {
		name: "",
		middlename: "",
		surname: "",
		phone: "",
		phone2: "",
		passport_series: "",
		issue: "",
		inn: "",
		pnfl: "",
		authority: "",
		birthday: "",
		region_id: "",
		city: "",
		home: "",
		work_place: ""
	}

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			try {
				setIsSubmitting(true)
				const response = await axiosPrivate.post(
					"/admin/custom/store",
					JSON.stringify(values),
					{ headers: { "Content-Type": "application/json" } }
				)
				if (response.data && response.data.status && response.data.data) {
					sendNotification({
						msg: t("order.modal.add.alerts.clientSuccess"),
						variant: "success"
					})
					setIsSubmitting(false)
					setClient(response.data.data)
					setCustomerId("")
				}
			} catch (error) {
				sendNotification({
					msg: error?.response?.data?.message || error?.message,
					variant: "error"
				})
				setIsSubmitting(false)
			}
		}
	})

	const formikPhysical = useFormik({
		initialValues: physicalInitialValues,
		validationSchema: physicalValidationSchema,
		onSubmit: async (values) => {
			try {
				setIsSubmitting(true)
				const response = await axiosPrivate.post(
					"/admin/custom/customstore",
					JSON.stringify(values),
					{ headers: { "Content-Type": "application/json" } }
				)
				if (response.data && response.data.status && response.data.data) {
					sendNotification({
						msg: t("order.modal.add.alerts.clientSuccess"),
						variant: "success"
					})
					setIsSubmitting(false)
					setClient(response.data.data)
					setClientsNotFound(false)
					setCustomerId("")
				}
			} catch (error) {
				sendNotification({
					msg: error?.response?.data?.message || error?.message,
					variant: "error"
				})
				setIsSubmitting(false)
			}
		}
	})

	const handleClose = () => {
		setCustomerId("")
		setClient("")
		setDate("")
		setClientsList([])
		setOpen(false)
		formik.resetForm()
		formikPhysical.resetForm()
	}

	const handleClient = (client) => {
		Array.from(document.getElementsByClassName("client-wrapper")).forEach(
			(item) =>
				item.id !== `client-${client.id}` &&
				item.classList.remove("item-selected")
		)
		document
			.getElementById(`client-${client.id}`)
			.classList.toggle("item-selected")
		isClientDataMore
			? formikPhysical.setValues(physicalInitialValues)
			: formik.setValues(initialValues)

		if (customerId === client.id) {
			setCustomerId("")
		} else {
			setCustomerId(client.id)
			if (isClientDataMore) {
				formikPhysical.setValues({
					name: client.name,
					middlename: client.middlename,
					surname: client.surname,
					phone: client.phone,
					phone2: formikPhysical.values.phone2
						? formikPhysical.values.phone2
						: client.phone2,
					passport_series: formikPhysical.values.passport_series
						? formikPhysical.values.passport_series
						: client.passport_series,
					issue: formikPhysical.values.issue
						? formikPhysical.values.issue
						: client.issue,
					inn: formikPhysical.values.inn
						? formikPhysical.values.inn
						: client.inn,
					pnfl: formikPhysical.values.pnfl
						? formikPhysical.values.pnfl
						: client.pnfl,
					authority: formikPhysical.values.authority
						? formikPhysical.values.authority
						: client.authority,
					birthday: formikPhysical.values.birthday
						? formikPhysical.values.birthday
						: client.birthday,
					region_id: formikPhysical.values.region_id
						? formikPhysical.values.region_id
						: client.region_id,
					city: formikPhysical.values.city
						? formikPhysical.values.city
						: client.city,
					home: formikPhysical.values.home
						? formikPhysical.values.home
						: client.home,
					work_place: formikPhysical.values.work_place
						? formikPhysical.values.work_place
						: client.work_place
				})
			} else {
				formik.setValues({
					name: client.name,
					middlename: client.middlename,
					surname: client.surname,
					phone: client.phone
				})
			}
		}
	}

	const handleOrder = async () => {
		let values = {
			home_id: homeData.id,
			custom_id: customerId,
			user_id: user.user.id,
			date: date
		}

		try {
			setIsOrderSubmitting(true)
			const response = await axiosPrivate.post(
				"/admin/order/store",
				JSON.stringify(values),
				{ headers: { "Content-Type": "application/json" } }
			)
			if (response.data && response.data.status && response.data.data) {
				sendNotification({
					msg: t("order.modal.add.alerts.homeSuccess"),
					variant: "success"
				})
				setIsOrderSubmitting(false)
				setRefetch(true)
				handleClose()
			}
		} catch (error) {
			sendNotification({
				msg: error?.response?.data?.message || error?.message,
				variant: "error"
			})
			setIsOrderSubmitting(false)
		}
	}

	const handleKeyDown = async (event) => {
		if (event.keyCode === 13) {
			setCustomerId("")
			setClientsLoading(true)
			const response = await axiosPrivate.get(
				isClientDataMore
					? `/dictionary/customs?name=${
							formikPhysical.values.name || ""
					  }&surname=${formikPhysical.values.surname || ""}&phone=${
							formikPhysical.values.phone.replace("+", "%2B") || ""
					  }
				&phone2=${formikPhysical.values.phone2.replace("+", "%2B") || ""}`
					: `/dictionary/customs?name=${formik.values.name || ""}&surname=${
							formik.values.surname || ""
					  }&phone=${formik.values.phone.replace("+", "%2B") || ""}`
			)
			if (response.data && response.data.status) {
				setClientsList(response.data.data.data)
				setClientsNotFound(true)
			}
			setClientsLoading(false)
		}
	}

	const handleChangeMoreInformation = (value) => {
		setIsClientDataMore(value)
		setCustomerId("")
		setClient("")
		setDate("")
		setClientsList([])
		setClientsNotFound(false)
	}

	const handleSearchClient = () => {
		if (
			formikPhysical.values.passport_series &&
			formikPhysical.values.birthday &&
			!(
				formikPhysical.touched.passport_series &&
				Boolean(formikPhysical.errors.passport_series) &&
				formikPhysical.touched.birthday &&
				Boolean(formikPhysical.errors.birthday)
			)
		) {
			setIsClientSearching(true)
			axios
				.get(
					`https://backsmartsales.uz/api/takedata?passport_series=${formikPhysical.values.passport_series}&birthday=${formikPhysical.values.birthday}`
				)
				.then((response) => {
					if (response && response.data && response.data.length > 0) {
						let dataJSON = response.data[0]
						if (
							dataJSON &&
							dataJSON?.data?.info &&
							dataJSON?.data?.info &&
							dataJSON?.data?.info?.data
						) {
							let newValues = {
								name: dataJSON?.data?.info?.data?.name || "",
								middlename: dataJSON?.data?.info?.data?.patronymic_name || "",
								surname: dataJSON?.data?.info?.data?.sur_name || "",
								phone: formikPhysical.values.phone || "",
								phone2: formikPhysical.values.phone2 || "",
								passport_series: formikPhysical.values.passport_series || "",
								issue: dataJSON?.data?.info?.data?.given_date || null,
								authority: dataJSON?.data?.info?.data?.given_place || "",
								birthday: formikPhysical.values.birthday || "",
								region_id: formikPhysical.values.region_id || "",
								city: formikPhysical.values.city || "",
								home: dataJSON?.data?.info?.data?.living_place
									? dataJSON?.data?.info?.data?.living_place
									: dataJSON?.data?.info?.data?.address,
								work_place: formikPhysical.values.work_place || "",
								inn: dataJSON?.data?.info?.data?.tin || "",
								pnfl: dataJSON?.data?.info?.data?.pinfl || ""
							}
							formikPhysical.setValues(newValues)
						}
					}
				})
				.catch((err) => {})
				.finally(() => setIsClientSearching(false))
		} else {
		}
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth="lg"
			disableEscapeKeyDown={true}
		>
			<DialogTitle id="order-dialog-title">
				<span className="text-xl">{t("order.modal.add.title")}</span>
				<div className="close-btn-wrapper">
					<IconButton variant="onlyIcon" color="primary" onClick={handleClose}>
						<i className="bi bi-x" />
					</IconButton>
				</div>
			</DialogTitle>

			<DialogContent>
				<div className="flex md:flex-row flex-col pb-8 order-home-modal-wrapper">
					<div
						className={`${
							isClientDataMore ? "lg:w-3/5" : "lg:w-1/3"
						} md:w-1/2 w-full home-client-wrapper`}
					>
						<SimpleSwitchField
							delay={0}
							label={t("common.fields.moreInformation")}
							value={isClientDataMore}
							setValue={handleChangeMoreInformation}
							options={[
								{
									value: true,
									checked: true
								},
								{
									value: false,
									checked: false
								}
							]}
						/>

						{isClientDataMore ? (
							<form onSubmit={formikPhysical.handleSubmit}>
								<Grid container spacing={2} rowSpacing={1} columns={12}>
									<Grid item={true} sm={12} xs={12}>
										<Grid
											container
											spacing={{ xs: 2, sm: 3, lg: 3 }}
											rowSpacing={1}
											columns={{ xs: 12, sm: 12, lg: 12 }}
										>
											<Grid item={true} sm={12} xs={12}>
												<SimpleRadioGroupField
													delay={0}
													name="citizenship"
													label={t("common.fields.citizenship")}
													value={citizenship}
													changeFn={(value) => setCitizenship(value)}
													options={citizenshipTypeSelectOptions}
													className="flex"
												/>
											</Grid>
											<Grid item={true} lg={4} sm={6} xs={12}>
												{citizenship === CITIZENSHIP_TYPE.UZBEKISTAN.code ? (
													<FormPassportField
														delay={0.1}
														label={t("common.fields.passportSeries")}
														fieldName="passport_series"
														formik={formikPhysical}
													/>
												) : (
													<FormTextField
														delay={0.1}
														label={t("common.fields.passportSeries")}
														fieldName="passport_series"
														formik={formikPhysical}
													/>
												)}
											</Grid>
											<Grid item={true} lg={4} sm={6} xs={12}>
												<FormDateField
													delay={0.2}
													label={t("common.fields.birthday")}
													fieldName="birthday"
													formik={formikPhysical}
												/>
											</Grid>
											<Grid item={true} lg={4} sm={6} xs={12}>
												{citizenship === CITIZENSHIP_TYPE.UZBEKISTAN.code && (
													<Button
														variant="outlined"
														color="info"
														onClick={() => handleSearchClient()}
														disabled={isClientSearching}
														className="!mt-2 !h-10"
														fullWidth
													>
														{isClientSearching ? (
															<div className="flex items-center justify-center">
																<CircularProgress size={17.5} color="inherit" />
															</div>
														) : (
															<div className="flex items-center text-base">
																<i className="bi bi-search mr-1 text-lg" />
																{t("client.addEdit.search")}
															</div>
														)}
													</Button>
												)}
											</Grid>
										</Grid>
									</Grid>

									<Grid item={true} sm={12} xs={12}>
										<div className="mt-2">
											<Divider />
											<div className="text-lg font-medium mt-3 -mb-2">
												{t("client.addEdit.clientDetails")}
											</div>
										</div>
									</Grid>

									<Grid item={true} lg={4} sm={6} xs={12}>
										<FormTextField
											delay={0.3}
											label={t("common.fields.name")}
											fieldName="name"
											formik={formikPhysical}
											onKeyDown={handleKeyDown}
										/>
									</Grid>

									<Grid item={true} lg={4} sm={6} xs={12}>
										<FormTextField
											delay={0.4}
											label={t("common.fields.surname")}
											fieldName="surname"
											formik={formikPhysical}
											onKeyDown={handleKeyDown}
										/>
									</Grid>

									<Grid item={true} lg={4} sm={6} xs={12}>
										<FormTextField
											delay={0.5}
											label={t("common.fields.middleName")}
											fieldName="middlename"
											formik={formikPhysical}
										/>
									</Grid>

									<Grid item={true} lg={4} sm={6} xs={12}>
										<FormPhoneField
											delay={0.6}
											label={t("common.fields.phone")}
											fieldName="phone"
											formik={formikPhysical}
											onKeyDown={handleKeyDown}
										/>
									</Grid>

									<Grid item={true} lg={4} sm={6} xs={12}>
										<FormPhoneField
											delay={0.7}
											label={t("common.fields.phone2")}
											fieldName="phone2"
											formik={formikPhysical}
											onKeyDown={handleKeyDown}
										/>
									</Grid>

									<Grid item={true} lg={4} sm={6} xs={12}>
										<FormPatternField
											delay={0.8}
											label={t("common.fields.tin")}
											fieldName="inn"
											formik={formikPhysical}
											format="#########"
										/>
									</Grid>

									<Grid item={true} sm={12} xs={12}>
										<div className="mt-2">
											<Divider />
											<div className="text-lg font-medium mt-3 -mb-2">
												{t("client.addEdit.passportDetails")}
											</div>
										</div>
									</Grid>

									<Grid item={true} lg={4} sm={6} xs={12}>
										<FormDateField
											delay={0.9}
											label={t("common.fields.issue")}
											fieldName="issue"
											formik={formikPhysical}
										/>
									</Grid>

									<Grid item={true} lg={4} sm={6} xs={12}>
										<FormTextField
											delay={1}
											label={t("common.fields.authority")}
											fieldName="authority"
											formik={formikPhysical}
										/>
									</Grid>

									<Grid item={true} lg={4} sm={6} xs={12}>
										<FormPatternField
											delay={1.1}
											label={t("common.fields.pnfl")}
											fieldName="pnfl"
											formik={formikPhysical}
											format="##############"
										/>
									</Grid>

									<Grid item={true} sm={12} xs={12}>
										<div className="mt-2">
											<Divider />
											<div className="text-lg font-medium mt-3 -mb-2">
												{t("client.addEdit.addressDetails")}
											</div>
										</div>
									</Grid>

									<Grid item={true} lg={4} sm={6} xs={12}>
										<FormAutocompleteField
											delay={1.2}
											fieldName="region_id"
											label={t("common.fields.region")}
											formik={formikPhysical}
											path={"/dictionary/regions"}
										/>
									</Grid>

									<Grid item={true} lg={4} sm={6} xs={12}>
										<FormTextField
											delay={1.3}
											label={t("common.fields.city")}
											fieldName="city"
											formik={formikPhysical}
										/>
									</Grid>

									<Grid item={true} lg={4} sm={6} xs={12}>
										<FormTextField
											delay={1.4}
											label={t("common.fields.home")}
											fieldName="home"
											formik={formikPhysical}
										/>
									</Grid>

									<Grid item={true} lg={4} sm={6} xs={12}>
										<FormTextField
											delay={1.5}
											label={t("common.fields.workPlace")}
											fieldName="work_place"
											formik={formikPhysical}
										/>
									</Grid>

									<Grid item={true} xs={12}>
										<div className="w-full flex items-center justify-center">
											<Button
												color="success"
												variant="contained"
												type="submit"
												disabled={isSubmitting || !!customerId}
											>
												{isSubmitting && (
													<CircularProgress
														size={15}
														color="inherit"
														className="mr-1"
													/>
												)}
												{t("order.modal.add.addClient")}
											</Button>
										</div>
									</Grid>
								</Grid>
							</form>
						) : (
							<form onSubmit={formik.handleSubmit}>
								<Grid container spacing={2} rowSpacing={1} columns={12}>
									<Grid item={true} xs={12}>
										<FormTextField
											delay={0}
											label={t("common.fields.name")}
											fieldName="name"
											formik={formik}
											onKeyDown={handleKeyDown}
										/>
									</Grid>

									<Grid item={true} xs={12}>
										<FormTextField
											delay={0}
											label={t("common.fields.surname")}
											fieldName="surname"
											formik={formik}
											onKeyDown={handleKeyDown}
										/>
									</Grid>

									<Grid item={true} xs={12}>
										<FormTextField
											delay={0}
											label={t("common.fields.middleName")}
											fieldName="middlename"
											formik={formik}
										/>
									</Grid>

									<Grid item={true} xs={12}>
										<FormPhoneField
											delay={0}
											label={t("common.fields.phone")}
											fieldName="phone"
											formik={formik}
											onKeyDown={handleKeyDown}
										/>
									</Grid>

									<Grid item={true} xs={12}>
										<Button
											color="success"
											variant="contained"
											type="submit"
											disabled={isSubmitting || !!customerId}
											className="w-full"
										>
											{isSubmitting && (
												<CircularProgress
													size={15}
													color="inherit"
													className="mr-1"
												/>
											)}
											{t("order.modal.add.addClient")}
										</Button>
									</Grid>
								</Grid>
							</form>
						)}

						{client && (
							<ButtonBase
								className="client-wrapper"
								onClick={() => handleClient(client)}
								id={`client-${client.id}`}
							>
								<span className="text-base font-medium">
									{client.name} {client.surname} {client.middlename}
								</span>
								<span className="text-sm">
									<PhoneFormat value={client.phone} />
								</span>
							</ButtonBase>
						)}
						{clientsLoading ? (
							<div className="circular-progress-box pt-6 pb-5">
								<CircularProgress size={30} />
							</div>
						) : clientsList && clientsList.length > 0 ? (
							clientsList.map((client) => (
								<ButtonBase
									className="client-wrapper"
									id={`client-${client.id}`}
									key={client.id}
									onClick={() => handleClient(client)}
								>
									<span className="text-base font-medium">
										{client.name} {client.surname} {client.middlename}
									</span>
									<span className="text-sm">
										<PhoneFormat value={client.phone} />
									</span>
								</ButtonBase>
							))
						) : (
							clientsNotFound && (
								<div className="mt-6">
									<span className="no-data-found-wrapper">
										<i className="bi bi-exclamation-octagon text-xl leading-4 mr-1" />{" "}
										{t("common.global.noDataFound")}
									</span>
								</div>
							)
						)}
					</div>
					<div
						className={`${
							isClientDataMore ? "lg:w-2/5" : "lg:w-2/3"
						} md:w-1/2 w-full md:mt-0 mt-4 pt-2 md:pl-8 home-data-wrapper`}
					>
						<div className="text-xl text-center">
							{t("order.modal.add.home.title")}
						</div>
						{homeData && (
							<Fragment>
								<div className="home-data-item">
									<div className="data-item-title">
										{t("order.modal.add.home.blockName")}:
									</div>
									<div className="data-item-value">{homeData?.name}</div>
								</div>

								<div className="home-data-item">
									<div className="data-item-title">
										{t("order.modal.add.home.homeNumber")}:
									</div>
									<div className="data-item-value">{homeData?.number}</div>
								</div>

								<div className="home-data-item">
									<div className="data-item-title">
										{t("order.modal.add.home.rooms")}:
									</div>
									<div className="data-item-value">{homeData?.rooms}</div>
								</div>

								<div className="home-data-item">
									<div className="data-item-title">
										{t("order.modal.add.home.areaAll")}:
									</div>
									<div className="data-item-value">{homeData?.square}</div>
								</div>

								<div className="home-data-item">
									<div className="data-item-title">
										{t("order.modal.add.home.stage")}:
									</div>
									<div className="data-item-value">{homeData?.stage}</div>
								</div>

								<div className="home-data-item">
									<div className="data-item-title">
										<Trans i18nKey="order.modal.add.home.repairedPrice">
											Ta'mirlangan narxi (1 m<sup>2</sup>)
										</Trans>
										:
									</div>
									<div className="data-item-value">
										{homeData.repaired && (
											<CurrencyFormat
												value={homeData.repaired}
												suffix={homeData?.isvalute === "1" ? " $" : " UZS"}
											/>
										)}
									</div>
								</div>

								<div className="home-data-item">
									<div className="data-item-title">
										<Trans i18nKey="order.modal.add.home.noRepairedPrice">
											Ta'mirlanmagan narxi (1 m<sup>2</sup>)
										</Trans>
										:
									</div>
									<div className="data-item-value">
										{homeData.norepaired && (
											<CurrencyFormat
												value={homeData.norepaired}
												suffix={homeData?.isvalute === "1" ? " $" : " UZS"}
											/>
										)}
									</div>
								</div>

								<div className="home-data-item">
									<div className="data-item-title">
										{t("order.modal.add.home.startPrice")}:
									</div>
									<div className="data-item-value">
										{homeData.start && (
											<CurrencyFormat
												value={parseFloat(
													(homeData?.start *
														(homeData?.isrepaired === REPAIR_TYPE.REPAIRED.code
															? formatMultiplies(
																	[homeData?.repaired, homeData?.square],
																	2
															  )
															: formatMultiplies(
																	[homeData?.norepaired, homeData?.square],
																	2
															  ))) /
														100
												)}
												suffix={homeData?.isvalute === "1" ? " $" : " UZS"}
											/>
										)}
									</div>
								</div>

								<div className="home-data-item">
									<div className="data-item-title">
										{t("order.modal.add.home.residentType")}:
									</div>
									<div className="data-item-value">
										{homeData.islive &&
											getLabelByTypeList(RESIDENT_TYPE, homeData.islive)}
									</div>
								</div>

								<div className="home-data-item">
									<div className="data-item-title">
										{t("order.modal.add.home.repairType")}:
									</div>
									<div className="data-item-value">
										{homeData.isrepaired &&
											getLabelByTypeList(REPAIR_TYPE, homeData.isrepaired)}
									</div>
								</div>

								<div className="home-data-item">
									<div className="data-item-title">
										{t("order.modal.add.additional")}:
									</div>
									<div className="data-item-value flex lg:flex-row flex-col lg:items-center">
										<Button
											color="info"
											variant="contained"
											className="!mr-2"
											disabled={!(homeData?.plan && homeData?.plan?.link)}
											onClick={() => setOpenHomeLayoutImageDialog(true)}
										>
											{t("common.button.homePlan")}
										</Button>

										<div>
											<SimpleDateField
												delay={0}
												name="last-date"
												label={t("order.modal.add.lastDate")}
												value={date}
												setValue={setDate}
											/>
										</div>
									</div>
								</div>
							</Fragment>
						)}
					</div>
				</div>
				<div className="flex items-center justify-end">
					<Button
						variant="contained"
						color="primary"
						disabled={!customerId || !date || isOrderSubmitting}
						onClick={handleOrder}
					>
						{isOrderSubmitting && (
							<CircularProgress size={15} color="inherit" className="mr-1" />
						)}
						{t("common.button.order")}
					</Button>
				</div>

				{openHomeLayoutImageDialog &&
					homeData?.plan &&
					homeData?.plan?.link && (
						<ImagePreviewDialog
							open={openHomeLayoutImageDialog}
							setOpen={setOpenHomeLayoutImageDialog}
							url={homeData?.plan?.link}
						/>
					)}
			</DialogContent>
		</Dialog>
	)
}

export default OrderAddModal
