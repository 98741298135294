import {
	Autocomplete,
	Button,
	ButtonBase,
	CircularProgress,
	Tab,
	Tabs,
	TextField,
	ToggleButton,
	ToggleButtonGroup
} from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { motion } from "framer-motion"
import { fadeUp, stepperItem } from "utils/motion"
import CurrencyFormat from "components/ui/text-formats/CurrencyFormat"
import { useFormik } from "formik"
import * as yup from "yup"
import useNotification from "hooks/useNotification"
import { NumericFormat } from "react-number-format"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import moment from "moment"
import StepThreeTabOne from "./step-three-tabs/StepThreeTabOne"
import StepThreeTabTwo from "./step-three-tabs/StepThreeTabTwo"
import useCurrency from "hooks/useCurrency"
import CurrencySubContent from "components/ui/text-formats/CurrencySubContent"
import { Trans, useTranslation } from "react-i18next"
import { formatMultiplies } from "utils/formatOddDecimal"
import { useQuery } from "react-query"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import usePermission from "hooks/usePermission"

const validationSchema = yup.object({
	month: yup
		.number()
		.default(1)
		.required("contract.step.three.validation.month"),
	date: yup
		.date()
		.nullable()
		.typeError("contract.step.three.validation.dateValid")
		.min(
			new Date("Sat Jan 01 2000 00:00:00 GMT+0500"),
			"contract.step.three.validation.dateMin"
		)
		.required("contract.step.three.validation.date"),
	startDate: yup
		.date()
		.nullable()
		.typeError("contract.step.three.validation.startDateValid")
		.min(
			new Date("Sat Jan 01 2000 00:00:00 GMT+0500"),
			"contract.step.three.validation.startDateMin"
		)
		.required("contract.step.three.validation.startDate"),
	sum: yup.number().default(0).required("contract.step.three.validation.sum"),
	left: yup.number().required("contract.step.three.validation.left"),
	discount: yup.number().default(0).optional(),
	interval: yup
		.number()
		.default(1)
		.required("contract.step.three.validation.interval"),
	startPrice: yup
		.number()
		.default(0)
		.required("contract.step.three.validation.startPrice"),
	price: yup
		.number()
		.default(0)
		.required("contract.step.three.validation.priceForSquare"),
	comment: yup.string().optional(),
	contractName: yup
		.string()
		.required("contract.step.three.validation.contractName"),
	operator_id: yup
		.number()
		.required("contract.step.three.validation.operatorId")
})

const StepThree = ({ appear, direction, next, back, homeData, setData }) => {
	const { t } = useTranslation()
	const [repairData, setRepairData] = useState("")
	const [isRepaired, setIsRepaired] = useState("0")
	const [paymentDataList, setPaymentDataList] = useState([])
	const [paymentDataList2, setPaymentDataList2] = useState([])
	const [tabValue, setTabValue] = useState("automatic")
	const { currencyData } = useCurrency()
	const [selectedCurrency, setSelectedCurrency] = useState("0")
	const sendNotification = useNotification()
	const axiosPrivate = useAxiosPrivate()
	const [hasError, setHasError] = useState(false)
	const { hasPermission } = usePermission()

	const formik = useFormik({
		initialValues: {
			month: "1",
			date: "",
			startDate: "",
			sum: "",
			left: "",
			discount: "0",
			interval: "1",
			startPrice: "0",
			price: "",
			comment: "",
			contractName: "",
			operator_id: ""
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {}
	})

	const handleNext = () => {
		if (!formik.isValid) {
			formik.handleSubmit()
		}
		let allSumPaymentDataList = paymentDataList.reduce(
			(acc, curr) => acc + curr?.sum,
			0
		)
		let allSumPaymentDataList2 = paymentDataList2.reduce(
			(acc, curr) => acc + curr?.sum,
			0
		)

		if (
			repairData &&
			formik.isValid /* &&
			((tabValue === "automatic" &&
				paymentDataList.length === formik.values.month &&
				allSumPaymentDataList === formik.values.left) ||
				(tabValue === "manually" &&
					paymentDataList2.length === formik.values.month &&
					allSumPaymentDataList2 === formik.values.left)) */
		) {
			setData({
				price: formik.values.price,
				sum: formik.values.sum,
				start_price: formik.values.startPrice,
				startDate: formik.values.startDate,
				month: formik.values.month,
				payments:
					tabValue === "automatic"
						? paymentDataList
						: tabValue === "manually"
						? paymentDataList2
						: [],
				discount: formik.values.discount,
				name: formik.values.contractName,
				comment: formik.values.comment,
				operator_id: formik.values.operator_id,
				isrepaired: isRepaired,
				isvalute: selectedCurrency
			})
			next()
		} else if (!repairData) {
			sendNotification({
				msg: t("contract.step.three.alerts.warning.repairType"),
				variant: "warning"
			})
		} else if (
			(tabValue === "automatic" &&
				paymentDataList.length !== formik.values.month) ||
			(tabValue === "manually" &&
				paymentDataList2.length !== formik.values.month)
		) {
			sendNotification({
				msg: t("contract.step.three.alerts.warning.paymentsNumber"),
				variant: "warning"
			})
		} else if (
			(tabValue === "automatic" &&
				allSumPaymentDataList !== formik.values.left) ||
			(tabValue === "manually" && allSumPaymentDataList2 !== formik.values.left)
		) {
			sendNotification({
				msg: t("contract.step.three.alerts.warning.leftPrice"),
				variant: "warning"
			})
		}
	}

	const handleRepairType = (type, data) => {
		Array.from(document.getElementsByClassName("repair-type")).forEach((item) =>
			item.classList.remove("item-selected")
		)
		if (type === "repaired" || type === "deal-price") {
			setIsRepaired("1")
			document.getElementById(type).classList.add("item-selected")
		} else if (type === "non-repaired") {
			setIsRepaired("0")
			document.getElementById(type).classList.add("item-selected")
		}
		formik.setFieldValue("price", data.price, true)
		formik.setFieldValue(
			"sum",
			formatMultiplies([data.square, data.price], 2),
			true
		)
		formik.setFieldValue(
			"left",
			formatMultiplies([data.square, data.price], 2) -
				formik.values.startPrice -
				formik.values.discount,
			true
		)
		setRepairData(data)
		setSelectedCurrency(data.isvalute)
	}

	const getPaymentData = () => {
		if (
			!isNaN(formik.values.month) &&
			!isNaN(formik.values.left) &&
			formik.values.date &&
			!isNaN(formik.values.interval)
		) {
			let arr = []
			for (let i = 1; i <= formik.values.month; i++) {
				arr.push({
					id: i,
					date: moment(formik.values.date)
						.add(formik.values.interval * (i - 1), "month")
						.format("YYYY-MM-DD"),
					left:
						formik.values.left -
						Math.floor(formik.values.left / formik.values.month) * (i - 1),
					sum: Math.floor(formik.values.left / formik.values.month)
				})
			}
			if (arr.length > 1) {
				arr[arr.length - 1] = {
					...arr[arr.length - 1],
					sum: arr[arr.length - 1].left
				}
			}
			setPaymentDataList(arr)
		}
	}

	const handleCurrencyChange = (value) => {
		setSelectedCurrency(value)
		if (currencyData && currencyData.sum) {
			if (value === "1") {
				formik.setFieldValue(
					"price",
					parseFloat(
						parseFloat((formik.values.price || "0") / currencyData.sum).toFixed(
							1
						)
					),
					true
				)
				formik.setFieldValue(
					"sum",
					parseFloat(
						parseFloat((formik.values.sum || "0") / currencyData.sum).toFixed(1)
					),
					true
				)
				formik.setFieldValue(
					"startPrice",
					parseFloat(
						parseFloat(
							(formik.values.startPrice || "0") / currencyData.sum
						).toFixed(1)
					),
					true
				)
				formik.setFieldValue(
					"left",
					parseFloat(
						parseFloat((formik.values.left || "0") / currencyData.sum).toFixed(
							1
						)
					),
					true
				)
				formik.setFieldValue(
					"discount",
					parseFloat(
						parseFloat(
							(formik.values.discount || "0") / currencyData.sum
						).toFixed(1)
					),
					true
				)
			} else if (value === "0") {
				formik.setFieldValue(
					"price",
					parseFloat((formik.values.price || "0") * currencyData.sum),
					true
				)
				formik.setFieldValue(
					"sum",
					parseFloat((formik.values.sum || "0") * currencyData.sum),
					true
				)
				formik.setFieldValue(
					"startPrice",
					parseFloat((formik.values.startPrice || "0") * currencyData.sum),
					true
				)
				formik.setFieldValue(
					"left",
					parseFloat((formik.values.left || "0") * currencyData.sum),
					true
				)
				formik.setFieldValue(
					"discount",
					parseFloat((formik.values.discount || "0") * currencyData.sum),
					true
				)
			}
		}
	}

	const {
		data: staffList,
		isLoading,
		isFetching
	} = useQuery({
		queryKey: "staffSelect",
		queryFn: async function () {
			const response = await axiosPrivate.get("/admin/staff/index")
			return response.data.data?.data || []
		},
		enabled: !hasError,
		onError: (error) => {
			setHasError(true)
		},
		retry: false
	})

	useEffect(() => {
		getPaymentData()
	}, [formik.values])

	useEffect(() => {
		if (homeData) {
			formik.setFieldValue(
				"contractName",
				`П-${homeData?.blocks?.name}/${homeData?.number}`,
				true
			)
			setSelectedCurrency(homeData?.isvalute || "0")
		}
	}, [homeData])

	return (
		<motion.div
			variants={stepperItem({
				direction: direction,
				duration: 0
			})}
			initial="hidden"
			animate={appear ? "show" : "hidden"}
		>
			<div className="component-add-wrapper">
				<div className="component-add-body overflow-y-auto h-full">
					<div className="home-repair-type">
						<div className="title">{t("contract.step.three.title")}</div>
						<div className="repair-types">
							<ButtonBase
								className="repair-type repair-type-non-repaired"
								id="non-repaired"
								onClick={() =>
									handleRepairType("non-repaired", {
										price: homeData?.norepaired,
										square: homeData?.square,
										isvalute: homeData?.isvalute
									})
								}
							>
								<div className="flex items-center justify-between w-full">
									<span>
										{t("contract.step.three.repairType.notRepaired")}:
									</span>
									<span>
										<CurrencyFormat
											value={homeData?.norepaired}
											suffix={homeData?.isvalute === "1" ? " $" : " UZS"}
										/>
									</span>
								</div>
								{currencyData &&
									currencyData.sum &&
									homeData?.isvalute === "1" && (
										<div className="price-by-currency-wrapper">
											=
											<CurrencyFormat
												value={formatMultiplies(
													[currencyData.sum, homeData?.norepaired],
													2
												)}
												className="ml-1"
											/>
										</div>
									)}
								<div className="flex items-center justify-between w-full mt-2">
									<span>
										{homeData?.square}{" "}
										<Trans i18nKey="common.global.meter">
											m<sup>2</sup>
										</Trans>
									</span>
									<span>
										{homeData?.square && homeData?.norepaired && (
											<CurrencyFormat
												value={formatMultiplies(
													[homeData?.square, homeData?.norepaired],
													2
												)}
												suffix={homeData?.isvalute === "1" ? " $" : " UZS"}
											/>
										)}
									</span>
								</div>
								{currencyData &&
									currencyData.sum &&
									homeData?.isvalute === "1" && (
										<div className="price-by-currency-wrapper">
											=
											<CurrencyFormat
												value={formatMultiplies(
													[
														currencyData.sum,
														parseFloat(homeData?.square * homeData?.norepaired)
													],
													2
												)}
												className="ml-1"
											/>
										</div>
									)}
							</ButtonBase>
							<ButtonBase
								className="repair-type repair-type-repaired !mx-4"
								id="repaired"
								onClick={() =>
									handleRepairType("repaired", {
										price: homeData?.repaired,
										square: homeData?.square,
										isvalute: homeData?.isvalute
									})
								}
							>
								<div className="flex items-center justify-between w-full">
									<span>{t("contract.step.three.repairType.repaired")}:</span>
									<span>
										<CurrencyFormat
											value={homeData?.repaired}
											suffix={homeData?.isvalute === "1" ? " $" : " UZS"}
										/>
									</span>
								</div>
								{currencyData &&
									currencyData.sum &&
									homeData?.isvalute === "1" && (
										<div className="price-by-currency-wrapper">
											=
											<CurrencyFormat
												value={formatMultiplies(
													[currencyData.sum, homeData?.repaired],
													2
												)}
												className="ml-1"
											/>
										</div>
									)}
								<div className="flex items-center justify-between w-full mt-2">
									<span>
										{homeData?.square}{" "}
										<Trans i18nKey="common.global.meter">
											m<sup>2</sup>
										</Trans>
									</span>
									<span>
										{homeData?.square && homeData?.repaired && (
											<CurrencyFormat
												value={formatMultiplies(
													[homeData?.square, homeData?.repaired],
													2
												)}
												suffix={homeData?.isvalute === "1" ? " $" : " UZS"}
											/>
										)}
									</span>
								</div>
								{currencyData &&
									currencyData.sum &&
									homeData?.isvalute === "1" && (
										<div className="price-by-currency-wrapper">
											=
											<CurrencyFormat
												value={formatMultiplies(
													[
														currencyData.sum,
														parseFloat(homeData?.square * homeData?.repaired)
													],
													2
												)}
												className="ml-1"
											/>
										</div>
									)}
							</ButtonBase>
							<ButtonBase
								className="repair-type repair-type-deal-price"
								id="deal-price"
								onClick={() =>
									handleRepairType("deal-price", {
										price: homeData?.deal_price,
										square: homeData?.square,
										isvalute: homeData?.isvalute
									})
								}
							>
								<div className="flex items-center justify-between w-full">
									<span>
										<Trans i18nKey="contract.step.three.repairType.dealPrice">
											Kelishilgan narxi(1 m<sup>2</sup>)
										</Trans>
										:
									</span>
									<span>
										<CurrencyFormat
											value={homeData?.deal_price}
											suffix={homeData?.isvalute === "1" ? " $" : " UZS"}
										/>
									</span>
								</div>
								{currencyData &&
									currencyData.sum &&
									homeData?.isvalute === "1" && (
										<div className="price-by-currency-wrapper">
											=
											<CurrencyFormat
												value={formatMultiplies(
													[currencyData.sum, homeData?.deal_price],
													2
												)}
												className="ml-1"
											/>
										</div>
									)}
								<div className="flex items-center justify-between w-full mt-2">
									<span>
										{homeData?.square}{" "}
										<Trans i18nKey="common.global.meter">
											m<sup>2</sup>
										</Trans>
									</span>
									<span>
										{homeData?.square && homeData?.deal_price && (
											<CurrencyFormat
												value={formatMultiplies(
													[homeData?.square, homeData?.deal_price],
													2
												)}
												suffix={homeData?.isvalute === "1" ? " $" : " UZS"}
											/>
										)}
									</span>
								</div>
								{currencyData &&
									currencyData.sum &&
									homeData?.isvalute === "1" && (
										<div className="price-by-currency-wrapper">
											=
											<CurrencyFormat
												value={formatMultiplies(
													[
														currencyData.sum,
														parseFloat(homeData?.square * homeData?.deal_price)
													],
													2
												)}
												className="ml-1"
											/>
										</div>
									)}
							</ButtonBase>
						</div>
						<div className="flex justify-center mt-4 h-14">
							<ToggleButtonGroup
								color="primary"
								value={selectedCurrency}
								exclusive
								onChange={(event) => handleCurrencyChange(event.target.value)}
								aria-label="currency"
							>
								<ToggleButton color="primary" value="0">
									UZS
								</ToggleButton>
								<ToggleButton color="primary" value="1">
									USD
								</ToggleButton>
							</ToggleButtonGroup>
						</div>
					</div>

					<div className="home-payment-wrapper mt-3">
						<div className="home-payment-details">
							<div className="flex flex-row justify-between">
								<div className="w-1/2 mx-20">
									<NumericFormat
										id="price-currency-field"
										name="price"
										label={
											<Trans i18nKey="contract.step.three.fields.priceForSquare">
												1 m<sup>2</sup> narxi
											</Trans>
										}
										value={formik.values.price}
										onChange={(event) => {
											let formattedValue =
												event.target.value &&
												parseFloat(event.target.value.split(" ").join(""))
											formik.setFieldValue("price", formattedValue, true)
											if (repairData?.square) {
												formik.setFieldValue(
													"sum",
													formatMultiplies(
														[formattedValue, repairData?.square],
														2
													)
												)
												formik.setFieldValue(
													"left",
													formatMultiplies(
														[formattedValue, repairData?.square],
														2
													) -
														formik.values.startPrice -
														formik.values.discount
												)
											}
										}}
										error={formik.touched.price && Boolean(formik.errors.price)}
										helperText={
											formik.touched.price && (
												<Trans i18nKey={formik.errors.price}>
													1 m<sup>2</sup> narxini kiritish majburiy!
												</Trans>
											)
										}
										component={motion.div}
										variants={fadeUp(30, "tween", 0.1, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										customInput={TextField}
										allowNegative={false}
										thousandSeparator={" "}
										decimalScale={1}
									/>
									{selectedCurrency === "1" && !isNaN(formik.values.price) && (
										<CurrencySubContent value={formik.values.price} />
									)}

									<NumericFormat
										id="sum-currency-field"
										name="sum"
										label={t("common.fields.contractSum")}
										value={formik.values.sum}
										onChange={(event) => {
											let formattedValue =
												event.target.value &&
												parseFloat(event.target.value.split(" ").join(""))
											formik.setFieldValue("sum", formattedValue, true)
										}}
										onBlur={() => {
											if (
												repairData?.square &&
												formik.values.sum !==
													formatMultiplies(
														[formik.values.price, repairData?.square],
														2
													)
											) {
												let newPrice = Math.ceil(
													formik.values.sum / repairData?.square
												)
												let mod = newPrice % 10
												formik.setFieldValue(
													"price",
													mod > 0 ? newPrice - mod + 10 : newPrice,
													true
												)
												formik.setFieldValue(
													"discount",
													(mod > 0 ? newPrice - mod + 10 : newPrice) *
														repairData?.square -
														formik.values.sum,
													true
												)
												formik.setFieldValue(
													"left",
													formik.values.sum - formik.values.startPrice,
													true
												)
												formik.setFieldValue(
													"sum",
													(mod > 0 ? newPrice - mod + 10 : newPrice) *
														repairData?.square,
													true
												)
											}
										}}
										error={formik.touched.sum && Boolean(formik.errors.sum)}
										helperText={formik.touched.sum && t(formik.errors.sum)}
										component={motion.div}
										variants={fadeUp(30, "tween", 0.1, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										customInput={TextField}
										allowNegative={false}
										thousandSeparator={" "}
										decimalScale={1}
										inputProps={{
											readOnly: true
										}}
									/>
									{selectedCurrency === "1" && !isNaN(formik.values.sum) && (
										<CurrencySubContent value={formik.values.sum} />
									)}

									<NumericFormat
										id="start-price-currency-field"
										name="start-price"
										label={t("common.fields.startPriceAmount")}
										value={formik.values.startPrice}
										onChange={(event) => {
											let formattedValue =
												event.target.value &&
												parseFloat(event.target.value.split(" ").join(""))
											formik.setFieldValue("startPrice", formattedValue, true)
											formik.setFieldValue(
												"left",
												formik.values.sum -
													formattedValue -
													formik.values.discount,
												true
											)
										}}
										error={
											formik.touched.startPrice &&
											Boolean(formik.errors.startPrice)
										}
										helperText={
											formik.touched.startPrice && t(formik.errors.startPrice)
										}
										component={motion.div}
										variants={fadeUp(30, "tween", 0.2, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										customInput={TextField}
										allowNegative={false}
										thousandSeparator={" "}
										decimalScale={1}
									/>
									{selectedCurrency === "1" &&
										!isNaN(formik.values.startPrice) && (
											<CurrencySubContent value={formik.values.startPrice} />
										)}

									<NumericFormat
										id="left-currency-field"
										name="left"
										label={t("common.fields.leftSum")}
										value={formik.values.left}
										onChange={(event) => {
											let formattedValue =
												event.target.value &&
												parseFloat(event.target.value.split(" ").join(""))
											formik.setFieldValue("left", formattedValue, true)
										}}
										error={formik.touched.left && Boolean(formik.errors.left)}
										helperText={formik.touched.left && t(formik.errors.left)}
										component={motion.div}
										variants={fadeUp(30, "tween", 0.3, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										customInput={TextField}
										allowNegative={false}
										thousandSeparator={" "}
										decimalScale={1}
										inputProps={{
											readOnly: true
										}}
									/>
									{selectedCurrency === "1" && !isNaN(formik.values.left) && (
										<CurrencySubContent value={formik.values.left} />
									)}

									<NumericFormat
										id="month"
										name="month"
										label={t("common.fields.loanRepayment")}
										value={formik.values.month}
										onChange={(event) => {
											formik.setFieldValue(
												"month",
												event.target.value
													? parseFloat(event.target.value)
													: "",
												true
											)
										}}
										error={formik.touched.month && Boolean(formik.errors.month)}
										helperText={formik.touched.month && t(formik.errors.month)}
										component={motion.div}
										variants={fadeUp(30, "tween", 0.4, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										customInput={TextField}
										allowNegative={false}
										decimalScale={0}
									/>

									<NumericFormat
										id="interval"
										name="interval"
										label={t("common.fields.interval")}
										value={formik.values.interval}
										onChange={(event) => {
											formik.setFieldValue(
												"interval",
												event.target.value
													? parseFloat(event.target.value)
													: "",
												true
											)
										}}
										error={
											formik.touched.interval && Boolean(formik.errors.interval)
										}
										helperText={
											formik.touched.interval && t(formik.errors.interval)
										}
										component={motion.div}
										variants={fadeUp(30, "tween", 0.5, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										customInput={TextField}
										allowNegative={false}
										decimalScale={0}
									/>

									<Autocomplete
										id="staff-autocomplete"
										options={staffList || []}
										disableClearable
										fullWidth
										loading={isLoading || isFetching}
										noOptionsText={t("common.global.noDataFound")}
										getOptionLabel={(option) =>
											option.name ? option.name : ""
										}
										onChange={(event, value) => {
											formik.setFieldValue(
												"operator_id",
												value.id ? value.id : "",
												true
											)
										}}
										loadingText={
											<div className="circular-progress-box">
												<CircularProgress size={25} />
											</div>
										}
										renderInput={(params) => (
											<TextField
												{...params}
												color="formColor"
												variant="outlined"
												component={motion.div}
												variants={fadeUp(30, "tween", 0.6, 0.5)}
												initial="hidden"
												animate="show"
												viewport={{ once: true, amount: 0.25 }}
												label={t("common.fields.intermediary")}
												error={
													formik.touched.operator_id &&
													Boolean(formik.errors.operator_id)
												}
												helperText={
													formik.touched.operator_id &&
													t(formik.errors.operator_id)
												}
												autoComplete="off"
											/>
										)}
									/>
								</div>

								<div className="w-1/2 mx-20">
									<LocalizationProvider dateAdapter={AdapterMoment}>
										<DatePicker
											id="start-date-picker"
											openTo="day"
											value={formik.values.startDate}
											onChange={(newValue) => {
												if (
													newValue &&
													newValue._isValid &&
													newValue > moment("2000-1-1")
												) {
													formik.setFieldValue(
														"startDate",
														moment(newValue).format("YYYY-MM-DD"),
														true
													)
												} else {
													formik.setFieldValue("startDate", "", true)
												}
											}}
											views={["year", "month", "day"]}
											inputFormat="DD/MM/yyyy"
											renderInput={(params) => (
												<TextField
													{...params}
													component={motion.div}
													variants={fadeUp(30, "tween", 0.5, 0.5)}
													initial="hidden"
													animate="show"
													viewport={{ once: true, amount: 0.25 }}
													color="formColor"
													variant="outlined"
													fullWidth
													id="start"
													name="start"
													label={t("common.fields.startDate")}
													error={
														formik.touched.startDate &&
														Boolean(formik.errors.startDate)
													}
													helperText={
														formik.touched.startDate &&
														t(formik.errors.startDate)
													}
													autoComplete="off"
												/>
											)}
										/>
									</LocalizationProvider>

									<LocalizationProvider dateAdapter={AdapterMoment}>
										<DatePicker
											id="date-date-picker"
											openTo="day"
											value={formik.values.date}
											onChange={(newValue) => {
												if (
													newValue &&
													newValue._isValid &&
													newValue > moment("2000-1-1")
												) {
													formik.setFieldValue(
														"date",
														moment(newValue).format("YYYY-MM-DD"),
														true
													)
												} else {
													formik.setFieldValue("date", "", true)
												}
											}}
											views={["year", "month", "day"]}
											inputFormat="DD/MM/yyyy"
											renderInput={(params) => (
												<TextField
													{...params}
													component={motion.div}
													variants={fadeUp(30, "tween", 0.5, 0.5)}
													initial="hidden"
													animate="show"
													viewport={{ once: true, amount: 0.25 }}
													color="formColor"
													variant="outlined"
													fullWidth
													id="date"
													name="date"
													label={t("common.fields.firstPriceDate")}
													error={
														formik.touched.date && Boolean(formik.errors.date)
													}
													helperText={
														formik.touched.date && t(formik.errors.date)
													}
													autoComplete="off"
												/>
											)}
										/>
									</LocalizationProvider>

									<NumericFormat
										id="discount-currency-field"
										name="discount"
										label={t("common.fields.discount")}
										value={formik.values.discount}
										onChange={(event) => {
											let formattedValue =
												event.target.value &&
												parseFloat(event.target.value.split(" ").join(""))
											formik.setFieldValue("discount", formattedValue, true)
											formik.setFieldValue(
												"left",
												formik.values.sum -
													formik.values.startPrice -
													formattedValue,
												true
											)
										}}
										error={
											formik.touched.discount && Boolean(formik.errors.discount)
										}
										helperText={
											formik.touched.discount && t(formik.errors.discount)
										}
										component={motion.div}
										variants={fadeUp(30, "tween", 0.3, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										customInput={TextField}
										allowNegative={false}
										thousandSeparator={" "}
										decimalScale={1}
									/>
									{selectedCurrency === "1" &&
										!isNaN(formik.values.discount) && (
											<CurrencySubContent value={formik.values.discount} />
										)}
									<TextField
										component={motion.div}
										variants={fadeUp(30, "tween", 0.4, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										id="contractName"
										name="contractName"
										label={t("common.fields.contractNumber")}
										error={
											formik.touched.contractName &&
											Boolean(formik.errors.contractName)
										}
										helperText={
											formik.touched.contractName &&
											t(formik.errors.contractName)
										}
										value={formik.values.contractName}
										onChange={(event) => {
											formik.setFieldValue(
												"contractName",
												event.target.value,
												true
											)
										}}
										autoComplete="off"
									/>

									<TextField
										component={motion.div}
										variants={fadeUp(30, "tween", 0.4, 0.5)}
										initial="hidden"
										animate="show"
										viewport={{ once: true, amount: 0.25 }}
										color="formColor"
										variant="outlined"
										fullWidth
										id="comment"
										name="comment"
										label={t("common.fields.comment")}
										multiline
										rows={6}
										error={
											formik.touched.comment && Boolean(formik.errors.comment)
										}
										helperText={
											formik.touched.comment && t(formik.errors.comment)
										}
										value={formik.values.comment}
										onChange={(event) => {
											formik.setFieldValue("comment", event.target.value, true)
										}}
										autoComplete="off"
									/>
								</div>
							</div>
						</div>
					</div>

					{!isNaN(formik.values.month) &&
						!isNaN(formik.values.left) &&
						formik.values.date &&
						!isNaN(formik.values.interval) && (
							<Fragment>
								<div className="step-three-payment-tables-wrapper">
									<Tabs
										value={tabValue}
										onChange={(event, newValue) => setTabValue(newValue)}
										className="contract-step-three-table-tabs"
									>
										<Tab
											label={t("contract.step.three.automaticTable")}
											value="automatic"
										/>
										<Tab
											label={t("contract.step.three.manuallyTable")}
											value="manually"
										/>
									</Tabs>
								</div>

								{tabValue === "automatic" && (
									<StepThreeTabOne
										month={formik.values.month}
										left={formik.values.left}
										date={formik.values.date}
										interval={formik.values.interval}
										paymentDataList={paymentDataList}
										setPaymentDataList={setPaymentDataList}
										currency={selectedCurrency}
									/>
								)}

								{tabValue === "manually" && (
									<StepThreeTabTwo
										month={formik.values.month}
										left={formik.values.left}
										date={formik.values.date}
										interval={formik.values.interval}
										paymentDataList={paymentDataList2}
										setPaymentDataList={setPaymentDataList2}
										currency={selectedCurrency}
									/>
								)}
							</Fragment>
						)}

					<div className="text-center my-4">
						<Button
							onClick={back}
							color="inherit"
							variant="contained"
							className="!mr-2"
						>
							{t("common.button.back")}
						</Button>
						<Button
							color="success"
							variant="contained"
							className="!ml-2"
							onClick={handleNext}
						>
							{t("common.button.next")}
						</Button>
					</div>
				</div>
			</div>
		</motion.div>
	)
}

export default StepThree
